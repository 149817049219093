<template>
  <div>
    <div class="xul" v-if="mp">
      <span class="cz"
        >该资源需消耗<span class="red">{{ Price }}</span
        >知识币<span v-show="Price*1 > Money*1"
          >知识币,当前知识币不足,请先去充值知识币</span
        >
        当前知识币<span class="red">{{ Money }}</span
        >个</span
      >
    </div>
    <div class="xul">
      <span class="cz" v-if="md">这是您上传的资源，可直接下载</span>
    </div>
    <div class="xul" v-if="dw">
      <span class="cz">您已购买，可直接下载</span>
    </div>
    <div class="czfs">
      <el-button type="primary" size="mini" @click="pay" v-if="mp"
        >确认支付</el-button
      >
      <el-button type="primary" size="mini" @click="indownload" v-if="df"
        >下载</el-button
      >
    </div>
  </div>
</template>

<script>
import { integralAddTwo } from "@/js/common";
import { INSERT, QUERYED, QUERY, UPDATE } from "@/services/dao.js";
import axios from "axios";
import {
  updatedownload,
  formatZero,
  getTimeFormat,
} from "@/js/common/Resoures";
export default {
  props: {
    Price: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    download: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      Money: "",
      mp: false,
      md: false,
      dw: false,
      df: false,
    };
  },
  created() {
    this.init();
    console.log(this.download);
    this.initpay();
    console.log("props", this.Price);
  },
  methods: {
    async init() {
      let info = await QUERYED(
        "post",
        "",
        "  PCUsers(where: {id: {_eq: " +
          this.$store.state.userinfo.id +
          "}}) {     Money     }"
      );
      this.Money = Number(info.data.PCUsers[0].Money).toFixed(2);
    },
    async initpay() {
      let billlist = await QUERYED(
        "post",
        "",
        "  ALLBill(where: {userid: {_eq: " +
          this.$store.state.userinfo.id +
          "},orderid: {_eq: " +
          this.id +
          "},state: {_eq: " +
          0 +
          "}}) {     id     }"
      );
      console.log("billlist: ", billlist);
      let infofilec = await QUERYED(
        "post",
        "",
        "  ZYDocument(where: {id: {_eq: " +
          this.id +
          "}}) {     file    name format user }"
      );
      console.log("infofilec: ", infofilec);

      if (infofilec.data.ZYDocument[0].user == this.$store.state.userinfo.id) {
        this.md = true;
        this.mp = false;
        this.dw = false;
        this.df = true;
      } else if (billlist.data.ALLBill.length > 0) {
        this.md = false;
        this.mp = false;
        this.dw = true;
        this.df = true;
      } else {
        this.md = false;
        this.mp = true;
        this.dw = false;
        this.df = false;
      }
    },
    async indownload() {
      let infofilec = await QUERYED(
        "post",
        "",
        "  ZYDocument(where: {id: {_eq: " +
          this.id +
          "}}) {     file  file    name format   userId}"
      );
      integralAddTwo(infofilec.data.ZYDocument[0].userId)//加两分
      let urla = infofilec.data.ZYDocument[0].file;
      let fileme =
        infofilec.data.ZYDocument[0].name +
        "." +
        infofilec.data.ZYDocument[0].format;
      const formData = new FormData();
      formData.append("path", urla);
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      axios
        .post(
          this.api.LoginURL.concat("/resources/trading/download"),
          formData,
          {
            responseType: "blob", //服务器返回的数据类型
          }
        )
        .then((res) => {
          console.log(res.data);
          //此处有个坑。这里用content保存文件流，最初是content=res，但下载的test.xls里的内容如下图1，
          //检查了下才发现，后端对文件流做了一层封装，所以将content指向res.data即可
          //另外，流的转储属于浅拷贝，所以此处的content转储仅仅是便于理解，并没有实际作用=_=
          const content = res.data;
          const blob = new Blob([content]); //构造一个blob对象来处理数据
          const fileName = fileme;
          //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
          //IE10以上支持blob但是依然不支持download
          if ("download" in document.createElement("a")) {
            //支持a标签download的浏览器
            const link = document.createElement("a"); //创建a标签
            link.download = fileName; //a标签添加属性
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link);
            link.click(); //执行下载
            URL.revokeObjectURL(link.href); //释放url
            document.body.removeChild(link); //释放标签
            updatedownload(this.id, this.download);
            this.$router.back(-1)
            // this.$router.push({
            //   path: "/resources_index",
            //   query: {},
            // });
          } else {
            //其他浏览器
            navigator.msSaveBlob(blob, fileName);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //支付
    async pay() {
      let infofilec = await QUERYED(
        "post",
        "",
        "  ZYDocument(where: {id: {_eq: " +
          this.id +
          "}}) {     file  file    name format  user }"
      );
      let orderNum = "";
      let num = String(formatZero(this.$store.state.userinfo.id, 7));
      let orderlist = await QUERYED(
        "post",
        "",
        "  ALLBill(where: {userid: {_eq: " +
          this.$store.state.userinfo.id +
          "}}) {     time  orderNum   }"
      );
      console.log("1", orderlist.data.ALLBill);
      if (orderlist.data.ALLBill.length < 1) {
        orderNum = getTimeFormat(new Date()) + num + "001";
      } else {
        for (let i = 0; i < orderlist.data.ALLBill.length; i++) {
          if (
            getTimeFormat(new Date()) ==
            getTimeFormat(orderlist.data.ALLBill[i].time)
          ) {
            let sic = orderlist.data.ALLBill[0].orderNum.substr(-3) * 1 + 1;

            orderNum =
              getTimeFormat(new Date()) + num + String(formatZero(sic, 3));
            console.log("2", orderNum);
            break;
          } else {
            orderNum = getTimeFormat(new Date()) + num + "001";
          }
        }
      }
      let ddid = "";
      console.log("3", orderNum, getTimeFormat(new Date()));
      let bilist = await QUERYED(
        "post",
        "",
        "  ALLBill(where: {userid: {_eq: " +
          this.$store.state.userinfo.id +
          "},orderid: {_eq: " +
          this.id +
          "},state: {_eq: " +
          1 +
          "}}) {     id     }"
      );
      if (bilist.data.ALLBill.length < 1) {
        console.log(orderNum);
        let infod = await INSERT(
          "POST",
          "",
          '   insert_bill(objects: {orderid: "' +
            this.id +
            '",orderNum: "' +
            orderNum +
            '", type: "2",classification: "1",state: "1", time: "' +
            new Date() +
            '", userid: "' +
            this.$store.state.userinfo.id +
            '",evaluate: "",  Price: "' +
            this.Price +
            '"}) { affected_rows  returning {id} }'
        );

        ddid = infod.data.insert_bill.returning[0].id;
      } else {
        ddid = bilist.data.ALLBill[bilist.data.ALLBill.length - 1].id;
      }

      if (this.Price*1 > this.Money*1) {
        this.$message.error("当前知识币不足,请先去充值知识币");
        let paymessge = await UPDATE(
          "post",
          "",
          "  update_bill(where: {id: {_eq: " +
            ddid +
            "}}, _set: {state: " +
            3 +
            "}) { affected_rows }"
        );
      } else {
        let difference = Number(this.Money) - Number(this.Price);
        let datdeds = await UPDATE(
          "post",
          "",
          "  update_User(where: {id: {_eq: " +
            this.$store.state.userinfo.id +
            "}}, _set: {Money: " +
            difference +
            "}) { affected_rows }"
        );

        let infov = await QUERYED(
          "post",
          "",
          "  PCUsers(where: {id: {_eq: " +
            infofilec.data.ZYDocument[0].user +
            "}}) {     Money     }"
        );

        let differencec =
          Number(infov.data.PCUsers[0].Money) + Number(this.Price);
        let datdedsa = await UPDATE(
          "post",
          "",
          "  update_User(where: {id: {_eq: " +
            infofilec.data.ZYDocument[0].user +
            "}}, _set: {Money: " +
            differencec +
            "}) { affected_rows }"
        );
        if (datdeds.data.update_User.affected_rows > 0) {
          this.$message({
            message: "支付成功",
            type: "success",
            duration: "1000",
          });
          integralAddTwo(this.$store.state.userinfo.id)
          let paysucces = await UPDATE(
            "post",
            "",
            "  update_bill(where: {id: {_eq: " +
              ddid +
              "}}, _set: {state: " +
              0 +
              "}) { affected_rows }"
          );
          let infofile = await QUERYED(
            "post",
            "",
            "  ZYDocument(where: {id: {_eq: " +
              this.id +
              "}}) {     file  file    name format   }"
          );
          // console.log("262"+info)
          let urla = infofile.data.ZYDocument[0].file;
          let fileme =
            infofile.data.ZYDocument[0].name +
            "." +
            infofile.data.ZYDocument[0].format;
          const formData = new FormData();
          formData.append("path", urla);
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          axios
            .post(
              this.api.LoginURL.concat("/resources/trading/download"),
              formData,
              {
                responseType: "blob", //服务器返回的数据类型
              }
            )
            .then((res) => {
              console.log(res);
              //此处有个坑。这里用content保存文件流，最初是content=res，但下载的test.xls里的内容如下图1，
              //检查了下才发现，后端对文件流做了一层封装，所以将content指向res.data即可
              //另外，流的转储属于浅拷贝，所以此处的content转储仅仅是便于理解，并没有实际作用=_=
              const content = res.data;
              const blob = new Blob([content]); //构造一个blob对象来处理数据
              const fileName = fileme;

              //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
              //IE10以上支持blob但是依然不支持download
              if ("download" in document.createElement("a")) {
                //支持a标签download的浏览器
                const link = document.createElement("a"); //创建a标签
                link.download = fileName; //a标签添加属性
                link.style.display = "none";
                link.href = URL.createObjectURL(blob);
                document.body.appendChild(link);
                link.click(); //执行下载
                URL.revokeObjectURL(link.href); //释放url
                document.body.removeChild(link); //释放标签
                updatedownload(this.id, this.download);
                this.$router.push({
                  path: "/resources_index",
                  query: {},
                });
              } else {
                //其他浏览器
                navigator.msSaveBlob(blob, fileName);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          let paymessge = await UPDATE(
            "post",
            "",
            "  update_bill(where: {id: {_eq: " +
              ddid +
              "}}, _set: {state: " +
              3 +
              "}) { affected_rows }"
          );
        }
      }
    },
    downaload(blobUrl) {
      const a = document.createElement("a");
      a.style.display = "none";
      a.download = this.name;
      a.href = blobUrl;
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>

<style scoped>
.xul {
  width: 100%;
  overflow: hidden;
  text-align: center;
}
.cz {
  margin-top: 55px;
  letter-spacing: 2px;
}
.red {
  color: red;
}
.money {
  float: left;
  /* width: 114px; */
  /* height: 114px; */
  background-color: #ffffff;
  /* line-height: 114px; */
  color: black;
  padding: 20px;
  margin-left: 10px;
}
.je {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
  line-height: 24px;
}
.jg {
  font-size: 48px;
  font-family: Microsoft YaHei;
  font-weight: 800;
  color: rgba(234, 74, 74, 1);
  line-height: 34px;
}
.czfs {
  width: 100%;
  overflow: hidden;
  margin-top: 30px;
  margin-left: 45%;
}
.czfs span {
  width: 163px;
  height: 54px;
  float: left;
  background-color: #ffffff;
  line-height: 64px;
  margin: 10px 30px 10px 30px;
}
.czfs span img {
  width: 22px;
  margin: 20px 10px 20px 20px;
  float: left;
}
</style>

<template>
  <div class="dl">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: 'Resource_transactions' }"
        >资源交易</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ classification }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
      <el-breadcrumb-item>下载文档</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="pay">
      <span class="title">购买文档:</span>
      <div class="introduction_center">
        <div class="introduction_center_left">
          <el-image :src="this.img"></el-image>
          <div class="comi">
            <span>{{ this.name }}</span
            ><br />
            <span>文档大小:{{ this.Febulous }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="payment">
      <V-payment
        :Price="Price"
        :id="id"
        :download="download"
        :name="name"
      ></V-payment>
    </div>
  </div>
</template>

<script>
import payment from "@/components/payment/index.vue";
import { INSERT, QUERY, DELETE, QUERYED } from "@/services/dao.js";
import { showTimeFormat ,Solution } from "@/js/common";
export default {
  components: {
    "V-payment": payment,
  },
  data() {
    return {
      img: require("@/assets/img/WORD.png"),
      name: "利用python进行数据分析",
      Febulous: "168kb",
      id: 190,
      Price: "",
      classification: "",
      download: 0,
      showWho: false // 
    };
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query.id != from.query.id) {
        this.init(); //重新加载数据
      }
    },
  },
  created() {
    this.id = Number(this.$route.query.id);
    console.log("111");
    this.info();
  },
  methods: {
    async info() {
      console.log("111");
      let articleInfo = await QUERYED(
        "POST",
        "",
        'ZYDocument(where: {id: {_eq: "' +
          this.id +
          '"}}) {    Price    Time   collection  download  classification  read  id   img  name  score  user  page Febulous file}'
      );
      
      let list = articleInfo.data.ZYDocument[0];

      // 查询是否订阅 并根据订阅类型 打折
      let resp2 = await QUERYED("post","",'SubscribedOfPay(where:{subscribers_user_id:{_eq:'+
        this.$store.state.userinfo.id+'},subscribed_user_id:{_eq:'+
        list.user+'},id:{_eq:'+
        this.id+'},state:{_eq:'+0+'},_not:{user:{_eq:'+
        this.$store.state.userinfo.id+'}}}) { subscribe_type state}');
        console.log("resp2: ",resp2);

      if(resp2.data.SubscribedOfPay.length){
        
        if(resp2.data.SubscribedOfPay[0].subscribe_type.search("一次性")> -1){
          list.Price = 0+"";
        }else{
          let discount = (resp2.data.SubscribedOfPay[0].subscribe_type.split("+折扣 (")[1].split(")")[0])/10*1.0
          console.log(discount);
          // list.Price = Math.round(list.Price * Number(discount),2)
          console.log(list.Price);
          list.Price = Solution(list.Price ,discount).toFixed(2)+""
          console.log(list.Price);
        }
        
      }

      this.classification = list.classification;
      // this.img = list.img;
      this.name = list.name;
      this.score = list.score;
      this.Price = list.Price;
      this.read = list.read;
      this.download = Number(list.download);
      this.Time = showTimeFormat(Date.parse(list.Time));
      this.page = list.page;
      this.user = list.user;
      this.Febulous = list.Febulous;
      if(list.img&&list.img!='null'){
        this.img = this.api.LoginURL.concat(list.img);
      }else{
        this.img = this.api.LoginURL.concat(list.file);
      }
      console.log(this.api.LoginURL.concat(list.img),123);
    },
  },
};
</script>
<style scoped>
.dl {
  background-color: #ffffff;
  margin: 0 161px 30px 139px;
  padding: 10px 20px 57px 0px;
}
.el-breadcrumb {
  margin: 30px 0px 0px 30px;
}
.pay {
  border: 1px solid #f7f7f7;
  margin: 10px;
  padding: 20px;
  margin-top: 65px;
}
.payment {
  border: 1px solid #f7f7f7;
  margin: 10px;
  padding: 80px 50px 50px 50px;
  margin-top: 41px;
  background-color: #f7f7f7;
}
.pay .title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 24px;
  float: left;
}
.introduction_center {
  overflow: hidden;
  background-color: #f7f7f7;
  width: 68%;
  margin-left: 8%;
}
.introduction_center_left {
  float: left;
  margin-left: 30px;
}
.introduction_center_left .el-image {
  width: 23px;
  float: left;
  margin-top: 13px;
}
.introduction_center_left span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
  line-height: 24px;
  margin-left: 10px;
}
.comi {
  float: left;
  padding: 10px;
}
</style>
